import { environment } from "./../environments/environment";
import { HttpHeaders } from "@angular/common/http";

export class AppConfig {
  /**
   * Dados de oauth.
   */
  static OAUTH_DATA: any;

  /**
   * Dados do usuário.
   */
  static USER: any;

  /**
   * Id de acesso.
   */
  static CLIENT_ID = "frontend-report";

  /**
   * Chave de acesso.
   */
  static CLIENT_SECRET = "cN7VffvtoaSg";

  /**
   * Url de autenticação.
   */
  static OAUTH_URL = environment.OAUTH_URL;

  /**
   * Url da api.
   */
  static API_URL_CADASTRO = environment.API_URL_CADASTRO;

  /**
   * Url da api de compras.
   */
  static API_URL_COMPRAS = environment.API_URL_COMPRAS;

  /**
   * Url da api de estoque.
   */
  static API_URL_ESTOQUE = environment.API_URL_ESTOQUE;

  /**
   * Url da api de financeiro.
   */
  static API_URL_FINANCEIRO = environment.API_URL_FINANCEIRO;

  /**
   * Url da api de vendas.
   */
  static API_URL_VENDAS = environment.API_URL_VENDAS;

  /**
   * Url da api preços
   */
  static API_URL_PRECOS = environment.API_URL_PRECOS;

  /**
   * Url da api fiscal
   */
  static API_URL_FISCAL = environment.API_URL_FISCAL;

  /**
   * Url da api comissoes
   */
  static API_URL_COMISSOES = environment.API_URL_COMISSOES;

  /**
   * Url da api.
   */
  static ASTUTUS_API_URL = environment.ASTUTUS_API_URL;

  /**
   * Url da api de s3.
   */
  static S3_API_URL = environment.S3_API_URL;

  /**
   * Url da api de report.
   */
  static API_URL_REPORT = environment.API_URL_REPORT;

  /**
   * Url da api do presto.
   */
  static API_URL_PRESTO = environment.API_URL_PRESTO;

  /**
   * Url da api do sync.
   */
  static API_URL_SYNC = environment.API_URL_SYNC;

  /**
   * Url da api de monitor.
   */
  static API_URL_MONITOR_NFE = environment.API_URL_MONITOR_NFE;

  /**
   * Url da API do Dominio
   */
  static API_URL_DOMINIO = environment.API_URL_DOMINIO;

  /**
   * Url do suporte
   */
  static URL_SUPORTE = "https://nexuscloud.movidesk.com/";

  /**
   * Url para integração com domínio
   */
  static URL_INTEGRACAO_DOMINIO = `${environment.INTEGRACAO_DOMINIO_URL}`;

  /**
   * Retorna o headers com o `content-type`.
   * @deprecated
   */
  static getHeaders() {
    return new HttpHeaders()
      .set("Content-Type", "application/json")
      .set("Authorization", `Bearer ${AppConfig.OAUTH_DATA.access_token}`);
  }

  /**
   * Retorna o headers com apenas o Authorization.
   */
  static getHeader() {
    return new HttpHeaders({
      "Content-Type": "application/json",
    });
  }

  static getAuthorizationHeader() {
    return new HttpHeaders().set(
      "Authorization",
      "Bearer " + AppConfig.OAUTH_DATA.access_token
    );
  }

  static getAuthorizationHeaderWithSchema(schema) {
    return new HttpHeaders()
      .set("Authorization", "Bearer " + AppConfig.OAUTH_DATA.access_token)
      .set("schema", schema);
  }

  static getHttpHeader(): HttpHeaders {
    return new HttpHeaders().set(
      "Authorization",
      `Bearer ${AppConfig.OAUTH_DATA.access_token}`
    );
  }
}
