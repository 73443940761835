import { Injectable } from "@angular/core";

@Injectable()
export class DownloadFilesService {
  donwloadBlobFile(fileName: string, blob: Blob) {
    const link = document.createElement("a");
    const url = window.URL.createObjectURL(blob);
    link.href = url;
    link.download = fileName;

    document.body.appendChild(link);

    link.click();

    setTimeout(function () {
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
    }, 0);
  }
}
