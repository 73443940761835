import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppConfig } from '@app/app.config';
import { AstutusComboboxService } from '@nexuscloud/components';

/**
 * Definição da classe.
 */
@Injectable()
export class ComboboxService extends AstutusComboboxService {
  /**
   * URL da api.
   */
  private baseUrl = `${AppConfig.ASTUTUS_API_URL}/combo/pesquisa`;

  /**
   * Recupera combos do localStorage
   */
  private combobox = JSON.parse(localStorage.getItem('@Report:combobox'));

  /**
   * Armazena histórico de combos
   */
  private historicoCombo: any = {};

  /**
   * Construtor.
   */
  constructor(private http: HttpClient) {
    super();
  }

  /**
   * Busca todos os combos.
   */
  public findAll(): any {
    return this.http.get(this.baseUrl);
  }

  /**
   * Busca entidades dentro combo no storage.
   */
  search(idComboPesquisa, nmEntidade = null, nmComboPesquisa = null) {
    const historico = this.historicoCombo[idComboPesquisa || nmComboPesquisa || nmEntidade];
    if (historico) {
      return historico;
    }
    const combo =
      this.combobox.find(item => item.idComboPesquisa === idComboPesquisa) ||
      this.combobox.find(item => item.nmComboPesquisa === nmComboPesquisa) ||
      this.combobox.find(item => item.entidade.nmEntidade === nmEntidade);
    if (combo) {
      this.historicoCombo[idComboPesquisa || nmComboPesquisa || nmEntidade] = combo;
      return combo;
    }
    return null;
  }
}
