import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { AuthAppGuard } from "./services/guard/auth-app.guard";
import { AuthLoginGuard } from "./services/guard/auth-login.guard";

const routes: Routes = [
  { path: "", redirectTo: "/login", pathMatch: "full" },
  {
    path: "login",
    loadChildren: () =>
      import("./pages/login/login.module").then((m) => m.LoginModule),
    canActivate: [AuthLoginGuard],
  },
  {
    path: "app",
    loadChildren: () =>
      import("./pages/app/app.module").then((m) => m.AppModule),
    canActivate: [AuthAppGuard],
    canActivateChild: [AuthAppGuard],
  },
  { path: "**", redirectTo: "/app" },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
    useHash: true
}),
  ],
  providers: [AuthLoginGuard, AuthAppGuard],
  exports: [RouterModule],
})
export class AppRoutingModule {}
