import { Directive, ElementRef, HostListener, Input, OnInit, Renderer2 } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

/**
 * @whatItDoes Força um input a receber apenas valores numéricos.
 *
 * @description Esta diretiva faz com que um input receba valores e então
 * os formata para o padrão numérico brasileiro. Os valores recebidos são
 * tratados para que não possuam letras ou caracteres especiais, a separação
 * milhares e de decimais são adicionadas automaticamente conforme
 * a digitação vai ocorrendo.
 *
 * @class NumericoNegativoDirective
 */
@Directive({
  selector: '[numericoNegativo]',
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: NumericoNegativoDirective,
    multi: true
  }],
})
export class NumericoNegativoDirective implements ControlValueAccessor, OnInit {

  @Input()
  numerico;

  /**
   * Valor real do campo sem a formatação visual.
   *
   * @type {number}
   */
  private _value: number;

  @Input()
  get value(): any {
    return this._value;
  }

  /**
   * Ao receber o valor converte para o tipo float e o armazena na variável _value.
   * Após isso, formata o valor para ser apresentado na view.
   *
   * @param value
   */
  set value(value: any) {
    // Converte o valor recebido.
    this._value = this.parseValue(value);
    // Formata o valor recebido para ser apresentado na view.
    this.renderer.setProperty(this.elementRef.nativeElement, 'value', value);
  }

  private onTouched = () => { };

  private onChange: (value: any) => void = () => { };

  constructor(
    private elementRef: ElementRef,
    private renderer: Renderer2) {
  }

  ngOnInit() {
    if (!this.numerico) {
      this.numerico = 2;
    }
  }

  // Implemented as part of ControlValueAccessor
  writeValue(value: any): void {
    this.value = this.clearValue(String(value || 0));
  }

  // Implemented as part of ControlValueAccessor
  registerOnChange(fn: (value: any) => void): void {
    this.onChange = fn;
  }

  // Implemented as part of ControlValueAccessor
  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  @HostListener('input', ['$event'])
  onInput($event: any) {
    const clearedValue = this.clearValue($event.target.value);
    this.value = clearedValue;
    this.onChange(this.value);
  }

  @HostListener('blur', ['$event'])
  onBlur() {
    this.onTouched();
  }

  /**
   * Limpa um valor recebido, deixando apenas caracteres numéricos
   * bloqueando assim a digitação dos demais.
   *
   * @param {string} value Valor recebido.
   * @returns {string} Valor processado.
   */
  private clearValue(value: string): string {
    let clearedValue = value.replace('.', ',').match(/[0-9,-]/g);

    if (clearedValue == null) {
      clearedValue = [''];
    }

    let result = clearedValue.join('');

    if (result[0] === ',') {
      return '';
    }

    const negativeIdx = result.indexOf('-');

    if (negativeIdx > 0) {
      result = result.substr(0, negativeIdx);
    }

    const commaFirst = result.indexOf(',');
    const commaLast = result.lastIndexOf(',');

    if (commaFirst > -1 && commaLast > -1 && commaFirst !== commaLast) {
      result = result.substr(0, commaLast);
    }

    const parts = result.split(',');

    if (parts[1]) {
      parts[1] = parts[1].substr(0, this.numerico);
    }

    result = parts.join(',');

    return result;
  }

  /**
   * Converte a string para number.
   *
   * @param value
   */
  private parseValue(value: string): number {
    return Number(value.replace(',', '.'));
  }

}
