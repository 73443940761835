import { AgGridColumnHelper } from './table/ag-grid/ag-grid-column-helper';
import { AgGridRenderer } from './table/ag-grid/ag-grid-renderer';
import { CommonModule, CurrencyPipe, DatePipe, DecimalPipe, PercentPipe } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatLegacyAutocompleteModule as MatAutocompleteModule } from '@angular/material/legacy-autocomplete';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatLegacyCardModule as MatCardModule } from '@angular/material/legacy-card';
import { MatLegacyCheckboxModule as MatCheckboxModule } from '@angular/material/legacy-checkbox';
import { MatLegacyChipsModule as MatChipsModule } from '@angular/material/legacy-chips';
import { DateAdapter, MatNativeDateModule, MatRippleModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import {
  MatLegacyDialogModule as MatDialogModule,
  MAT_LEGACY_DIALOG_DEFAULT_OPTIONS as MAT_DIALOG_DEFAULT_OPTIONS,
} from '@angular/material/legacy-dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatLegacyListModule as MatListModule } from '@angular/material/legacy-list';
import { MatLegacyMenuModule as MatMenuModule } from '@angular/material/legacy-menu';
import { MatLegacyPaginatorModule as MatPaginatorModule } from '@angular/material/legacy-paginator';
import { MatLegacyProgressBarModule as MatProgressBarModule } from '@angular/material/legacy-progress-bar';
import { MatLegacyProgressSpinnerModule as MatProgressSpinnerModule } from '@angular/material/legacy-progress-spinner';
import { MatLegacyRadioModule as MatRadioModule } from '@angular/material/legacy-radio';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatLegacySlideToggleModule as MatSlideToggleModule } from '@angular/material/legacy-slide-toggle';
import { MatLegacySliderModule as MatSliderModule } from '@angular/material/legacy-slider';
import { MatLegacySnackBarModule as MatSnackBarModule } from '@angular/material/legacy-snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatStepperModule } from '@angular/material/stepper';
import { MatLegacyTableModule as MatTableModule } from '@angular/material/legacy-table';
import { MatLegacyTabsModule as MatTabsModule } from '@angular/material/legacy-tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';
import { ConfirmDialogComponent } from '@app/shared/components/confirm-dialog/confirm-dialog.component';
import { OnlyNumberDirective } from '@app/shared/directives/only-number.directive';
import { CheckboxComponent } from '@shared/components/check-box/check-box.component';
import { SingleCheckboxComponent } from '@shared/components/check-box/single-check-box.component';
import { DateAdapterBr } from '@shared/components/datepicker/date-adapter-br';
import { AstutusFloatLabelDirective } from '@shared/directives/astutus-float-label.directive';
import { AutofocusDirective } from '@shared/directives/autofocus.directive';
import { DateMaskDirective } from '@shared/directives/date-mask.directive';
import { NumericoNegativoDirective } from '@shared/directives/numerico-negativo.directive';
import { NumericoDirective } from '@shared/directives/numerico.directive';
import { ProgressBarComponent } from '@shared/directives/progress-bar.component';
import { TelefoneDirective } from '@shared/directives/telefone.directive';
import { TextFormatDirective } from '@app/shared/directives/text-format.directive';
import { ReuseStrategyObservable } from '@shared/router/reuse-strategy-observable';
import { RadioButtonService } from './components/radio-button/radio-button-service';
import { RadioButtonComponent } from './components/radio-button/radio-button.component';
import { CpfCnpjFormatDirective } from './directives/cpf-cnpj-format.directive';
import { OnlyNumberTextDirective } from './directives/only-number-text.directive';
import { SelectOnFocusDirective } from './directives/select-on-focus.directive';
import { StepperTouchDirective } from './directives/stepper-touch.directive';
import { TrimDirective } from './directives/trim';
import { ComponentRouteResetService } from './router/component-route-reset.service';
import { RemoveDialogComponent } from './components/remove-dialog/remove-dialog.component';
import { AgGridModule } from 'ag-grid-angular';
import { AgGridResizeApiDirective, AgGridResizeDirective, AgGridResizeHeaderDirective } from './table/ag-grid';
import { DownloadFilesService } from './components/download-file-service/download-file.service';
import { CurrencyMaskModule } from './directives/currency/currency-mask.module';
import { FormularioModule } from '@nexuscloud/components';

// https://angular.io/guide/styleguide#shared-feature-module
@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatAutocompleteModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatCardModule,
    MatCheckboxModule,
    MatChipsModule,
    MatTableModule,
    MatDatepickerModule,
    MatDialogModule,
    MatExpansionModule,
    MatFormFieldModule,
    MatGridListModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatMenuModule,
    MatPaginatorModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatRippleModule,
    MatSelectModule,
    MatSidenavModule,
    MatSlideToggleModule,
    MatSliderModule,
    MatSnackBarModule,
    MatSortModule,
    MatStepperModule,
    MatTabsModule,
    MatToolbarModule,
    MatTooltipModule,
    MatNativeDateModule,
    AgGridModule,
    FormularioModule,
  ],
  declarations: [
    DateMaskDirective,
    NumericoDirective,
    NumericoNegativoDirective,
    TextFormatDirective,
    AutofocusDirective,
    TelefoneDirective,
    TrimDirective,
    AstutusFloatLabelDirective,
    ProgressBarComponent,
    RemoveDialogComponent,
    CheckboxComponent,
    SingleCheckboxComponent,
    RadioButtonComponent,
    OnlyNumberDirective,
    OnlyNumberTextDirective,
    SelectOnFocusDirective,
    ConfirmDialogComponent,
    StepperTouchDirective,
    CpfCnpjFormatDirective,
    AgGridResizeDirective,
    AgGridResizeApiDirective,
    AgGridResizeHeaderDirective,
  ],
  providers: [
    RadioButtonService,
    { provide: DateAdapter, useClass: DateAdapterBr },
    ComponentRouteResetService,
    AgGridColumnHelper,
    DownloadFilesService,
  ],
  exports: [
    CommonModule,
    AgGridModule,
    FormsModule,
    ReactiveFormsModule,
    DateMaskDirective,
    NumericoDirective,
    NumericoNegativoDirective,
    TrimDirective,
    TextFormatDirective,
    TelefoneDirective,
    AstutusFloatLabelDirective,
    AutofocusDirective,
    ProgressBarComponent,
    MatAutocompleteModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatCardModule,
    MatCheckboxModule,
    MatChipsModule,
    MatTableModule,
    MatDatepickerModule,
    MatDialogModule,
    MatExpansionModule,
    MatFormFieldModule,
    MatGridListModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatMenuModule,
    MatPaginatorModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatRippleModule,
    MatSelectModule,
    MatSidenavModule,
    MatSlideToggleModule,
    MatSliderModule,
    MatSnackBarModule,
    MatSortModule,
    MatStepperModule,
    MatTabsModule,
    MatToolbarModule,
    MatTooltipModule,
    MatNativeDateModule,
    RemoveDialogComponent,
    OnlyNumberDirective,
    OnlyNumberTextDirective,
    SelectOnFocusDirective,
    StepperTouchDirective,
    CpfCnpjFormatDirective,
    AgGridResizeDirective,
    AgGridResizeApiDirective,
    AgGridResizeHeaderDirective,
    CurrencyMaskModule,
  ],
})

// https://github.com/ocombe/ng2-translate/issues/209
export class SharedModule {
  static forRoot(): ModuleWithProviders<SharedModule> {
    return {
      providers: [
        ReuseStrategyObservable,
        DecimalPipe,
        CurrencyPipe,
        AgGridRenderer,
        PercentPipe,
        DatePipe,
        {
          provide: MAT_DIALOG_DEFAULT_OPTIONS,
          useValue: { hasBackdrop: true, direction: 'ltr' },
        },
      ],
      ngModule: SharedModule,
    };
  }
}
