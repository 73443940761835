import { Big } from 'big.js';

export class BigDecimal {
  static multiply(a = 0, b = 0, fixed = 2) {
    return new Big(a).mul(b).round(fixed, Big.roundHalfUp).toNumber();
  }

  static divide(a = 0, b = 0, fixed = 2) {
    return new Big(a).div(b).round(fixed, Big.roundHalfUp).toNumber();
  }

  static add(a = 0, b = 0, fixed = 2) {
    return new Big(a).plus(b).round(fixed, Big.roundHalfUp).toNumber();
  }

  static addAll(values: number[], fixed = 2) {
    return values.reduce((acc, value) => BigDecimal.add(acc, value, fixed));
  }

  static subtract(a = 0, b = 0, fixed = 2) {
    return new Big(a).minus(b).round(fixed, Big.roundHalfUp).toNumber();
  }

  static round(value = 0, fixed = 2) {
    return new Big(value).round(fixed, Big.roundHalfUp).toNumber();
  }

  static percentage(a = 0, total = 0) {
    return new Big(a).div(total).mul(100).toNumber();
  }

  static diffPercentual(final = 0, initial = 0) {
    return new Big(initial).minus(final).div(initial).mul(100).toNumber();
  }

  static negate(value = 0, fixed = 2) {
    return BigDecimal.multiply(value, -1, fixed);
  }
}
