import { AfterViewChecked, Directive, ElementRef, Input, Renderer2 } from '@angular/core';

/**
 * @whatItDoes Disponibiliza uma diretiva que irá redimensionar o container
 * e as colunas do ag-grid automaticamente.
 *
 * @description Esta diretiva possui funções utilizadas internamente para
 * controlar os sizes do ag-grid de forma que possa ficar melhor ajustado
 * visualmente, fixando corretamente as colunas e a altura do grid.
 *
 * @class AgGridResizeDirective
 * @experimental
 */
@Directive({
  selector: '[agGridResize]',
})
export class AgGridResizeDirective implements AfterViewChecked {
  /**
   * Os itens a serem exibidos no ag-grid.
   *
   * @type {Array<any>}
   */
  @Input() rowData: Array<any>;

  /**
   * Atributo utilizado para fixar a altura do ag-grid levando
   * em conta quantos elementos devem ser exibidos.
   *
   * @type {number}
   */
  @Input() pinnedRows: any = 5;

  /**
   * Tamanho do grid criado pelo ag-grid.
   *
   * @type {number}
   */
  private containerWidth: number;

  /**
   * Altura do grid criado pelo ag-grid.
   *
   * @type {number}
   */
  private containerHeight: number;

  /**
   * Tamanho do grid fixo.
   *
   * @type {number}
   */
  private pinnedContainerWidth: number;

  /**
   * Todas as colunas do grid.
   *
   * @type {NodeList}
   */
  private columns: NodeList;

  /**
   * Tamanho máximo de uma coluna.
   *
   * @type {number}
   */
  private columnsNumber = 12;

  /**
   * Altura de cada linha do grid.
   *
   * @type {number}
   */
  private defaultRowHeight = 46;

  /**
   * Definição da coluna com nome da classe e tamanho.
   *
   * @type {any[]}
   */
  private columnsType: Array<{ className: string; columnNumber: number }> = [];

  /**
   * Espaço deixado no início e no final de cada linha do grid.
   *
   * @type {number}
   */
  private gridGutterWidth = 14 * 2;

  constructor(private element: ElementRef, private renderer: Renderer2) {
    // Inicializa as classes utilizadas para identificar as colunas
    for (let i = 1; i <= this.columnsNumber; i += 1) {
      this.columnsType.push({
        className: `col-grid-${i}`,
        columnNumber: i,
      });
    }
  }

  ngAfterViewChecked() {
    // Caputra o tamanho do container removendo o gutter para que a soma das colunas não ultrapasse
    // o tamanho total do container gerando um scroll bar indesejado.
    this.containerWidth =
      this.element.nativeElement.getBoundingClientRect().width - this.gridGutterWidth;
    this.containerHeight = this.element.nativeElement.getBoundingClientRect().height;

    this.recalculateSizeColumns('ag-header-cell');
    // Importante: Essa função deve ser executada logo após identificar
    // as colunas fixas para minimizar efeitos visuais indesejados.
    this.recalculatePinnedContainerPosition();
    this.recalculateSizeColumns('ag-cell');

    if (this.pinnedRows !== 'auto') {
      this.recalculateContainerHeight();
    }
  }

  resizeGridCell() {
    // Caputra o tamanho do container removendo o gutter para que a soma das colunas não ultrapasse
    // o tamanho total do container gerando um scroll bar indesejado.
    this.containerWidth =
      this.element.nativeElement.getBoundingClientRect().width - this.gridGutterWidth;
    this.containerHeight = this.element.nativeElement.getBoundingClientRect().height;

    this.recalculateSizeColumns('ag-header-cell');
    // Importante: Essa função deve ser executada logo após identificar
    // as colunas fixas para minimizar efeitos visuais indesejados.
    this.recalculatePinnedContainerPosition();
    this.recalculateSizeColumns('ag-cell');

    if (this.pinnedRows !== 'auto') {
      this.recalculateContainerHeight();
    }
  }

  /**
   * Recalcula o tamanho de cada coluna do grid com base no
   * valor representado pela porcentagem de 12/100.
   *
   * @param {string} columnClass
   */
  private recalculateSizeColumns(columnClass: string): void {
    this.columns = this.element.nativeElement.querySelectorAll(`.${columnClass}`);
    this.pinnedContainerWidth = 0;

    for (let i = 0; i < this.columns.length; i += 1) {
      const column = this.columns.item(i);
      const { classList } = column as any;

      this.columnsType.forEach(columnType => {
        const contains = classList.contains(columnType.className);
        const percentByColumn = 100 / this.columnsNumber / 100;
        const columnWidth = percentByColumn * columnType.columnNumber * this.containerWidth;

        if (contains) {
          this.renderer.removeStyle(column, 'width');
          this.renderer.setStyle(column, 'width', `${columnWidth}px`);
        }

        // calcula o width pinned somente se for uma coluna do cabeçalho
        if (contains && classList.contains('pinned-cell')) {
          this.pinnedContainerWidth += columnWidth;
        }
      });
    }
  }

  /**
   * Modifica o tamanho do grid fixo com base no tamanho das colunas fixas.
   * O Ag-grid executa seu próprio calculo para definir esse valor, porem causa inconsistência  quando
   * utilizado colunas que possuam seu tamanho  definido por porcentagem.
   *
   */
  private recalculatePinnedContainerPosition(): void {
    const pinnedLeftHeader = this.element.nativeElement.querySelector('.ag-pinned-left-header');
    const headerViewport = this.element.nativeElement.querySelector('.ag-header-viewport');
    const pinnedLeftColsViewport = this.element.nativeElement.querySelector(
      '.ag-pinned-left-cols-viewport'
    );
    const pinnedLeftColsContainer = this.element.nativeElement.querySelector(
      '.ag-pinned-left-cols-container'
    );
    const bodyViewportWrapper = this.element.nativeElement.querySelector(
      '.ag-body-viewport-wrapper'
    );

    if (this.pinnedContainerWidth) {
      this.renderer.setStyle(pinnedLeftHeader, 'width', `${this.pinnedContainerWidth}px`);
      this.renderer.setStyle(headerViewport, 'margin-left', `${this.pinnedContainerWidth}px`);
      this.renderer.setStyle(pinnedLeftColsViewport, 'width', `${this.pinnedContainerWidth}px`);
      this.renderer.setStyle(pinnedLeftColsContainer, 'width', `${this.pinnedContainerWidth}px`);
      this.renderer.setStyle(bodyViewportWrapper, 'margin-left', `${this.pinnedContainerWidth}px`);
    }
  }

  /**
   * Recalcula a altura máxima do ag-grid. O cálculo é feito com base em quantas
   * linhas devem ser exibidas no grid então a altura do grid é fixada a partir disso.
   */
  private recalculateContainerHeight(): void {
    let numberItems = (this.rowData || []).length;
    numberItems = numberItems < this.pinnedRows ? numberItems : this.pinnedRows;

    const headerHeight =
      this.element.nativeElement.querySelector('.ag-header-viewport').getBoundingClientRect()
        .height + 5;
    const containerHeight = numberItems * this.defaultRowHeight;

    if (headerHeight) {
      this.renderer.removeStyle(this.element.nativeElement, 'height');
      this.renderer.setStyle(
        this.element.nativeElement,
        'height',
        `${containerHeight + headerHeight}px`
      );
    }
  }
}
