import { AstutusComboboxService } from '@nexuscloud/components';
import { AstutusPesquisaRoutingController, ASTUTUS_URL_BASE } from '@nexuscloud/components';
import { ClienteReadService } from './services/cliente/cliente.service';
import { registerLocaleData } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import locale from '@angular/common/locales/pt';
import { ErrorHandler, Injector, LOCALE_ID, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouteReuseStrategy } from '@angular/router';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AppRoutingController } from './pages/app/app.routing.controller';
import { SessionStorageService } from './services/sessionstorage.service';
import { GlobalErrorHandler } from './shared/error-handler/global-error-handler';
import { DateConverterInterceptor } from './shared/interceptors/date-converter-interceptor';
import { CustomReuseStrategy } from './shared/router/custom-reuse-strategy';
import { SharedModule } from './shared/shared.module';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { AuthInterceptor } from './shared/auth/auth.interceptor';
import { OauthService } from './services/oauth/oauth.service';
import { PerfilamentoService } from './services/perfilamento/perfilamento.service';
import { AppConfig } from './app.config';
import { ComboboxService } from './services/combobox/combobox.service';

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    BrowserAnimationsModule,
    SharedModule.forRoot(),
    AppRoutingModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
      registrationStrategy: 'registerImmediately',
    }),
  ],
  providers: [
    OauthService,
    PerfilamentoService,
    ClienteReadService,
    AppRoutingController,
    SessionStorageService,
    {
      provide: AstutusComboboxService,
      useClass: ComboboxService,
    },
    {
      provide: AstutusPesquisaRoutingController,
      useClass: AppRoutingController,
    },
    {
      provide: ASTUTUS_URL_BASE,
      useValue: {
        aut: AppConfig.OAUTH_URL,
        cad: AppConfig.API_URL_CADASTRO,
        com: AppConfig.API_URL_COMPRAS,
        dic: AppConfig.ASTUTUS_API_URL,
        est: AppConfig.API_URL_ESTOQUE,
        dom: AppConfig.API_URL_DOMINIO,
        fin: AppConfig.API_URL_FINANCEIRO,
        fis: AppConfig.API_URL_FISCAL,
        pre: AppConfig.API_URL_PRECOS,
        ven: AppConfig.API_URL_VENDAS,
        rep: AppConfig.API_URL_REPORT,
      },
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: DateConverterInterceptor,
      multi: true,
    },
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    {
      provide: RouteReuseStrategy,
      useClass: CustomReuseStrategy,
    },
    { provide: LOCALE_ID, useValue: 'pt-BR' },
    { provide: ErrorHandler, useClass: GlobalErrorHandler },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  static injector: Injector;

  constructor(injector: Injector) {
    AppModule.injector = injector;

    registerLocaleData(locale);
  }
}
