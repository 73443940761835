import { Column } from "@ag-grid-community/core";
import {
  AfterViewInit,
  Directive,
  ElementRef,
  Input,
  OnDestroy,
  Renderer2,
} from "@angular/core";
import { BigDecimal } from "@app/shared/utils/big-decimal";
import { Subject } from "rxjs";
import {
  debounceTime,
  distinctUntilChanged
} from "rxjs/operators";

/**
 * @whatItDoes Disponibiliza uma diretiva que irá redimensionar o container
 * e as colunas do ag-grid automaticamente.
 *
 * @description Esta diretiva possui funções utilizadas internamente para
 * controlar os sizes do ag-grid de forma que possa ficar melhor ajustado
 * visualmente, fixando corretamente as colunas e a altura do grid.
 *
 * @class AgGridResizeDirective
 * @experimental
 */

declare let ResizeObserver;
@Directive({
  selector: "[agGridResizeApi]",
})
export class AgGridResizeApiDirective implements AfterViewInit, OnDestroy {
  /**
   * Os itens a serem exibidos no ag-grid.
   *
   * @type {Array<any>}
   */
  @Input() rowData: Array<any>;

  /**
   * Atributo utilizado para fixar a altura do ag-grid levando
   * em conta quantos elementos devem ser exibidos.
   *
   * @type {number}
   */
  @Input() pinnedRows: number | string = 5;

  @Input() gridOptions;

  @Input() autoSizeColumns = false;

  /**
   * Tamanho do grid criado pelo ag-grid.
   *
   * @type {number}
   */
  private containerWidth: number;

  /**
   * Tamanho máximo de uma coluna.
   *
   * @type {number}
   */
  private columnsNumber = 12;

  /**
   * Altura de cada linha do grid.
   *
   * @type {number}
   */
  private defaultRowHeight = 48;

  /**
   * Definição da coluna com nome da classe e tamanho.
   *
   * @type {any[]}
   */
  private columnsType: Array<{ className: string; columnNumber: number }> = [];

  /**
   * Espaço deixado no início e no final de cada linha do grid.
   *
   * @type {number}
   */
  private gridGutterWidth = 14 * 2;

  /**
   * Timer para evitar muitos recálculo no tamanho do grid ao fazer resize do container
   */
  timeOut;

  /**
   * Observa alterações no tamanho do container
   */
  observer;

  /**
   * Container por volta do grid
   */
  container;

  private containerWidthChanged = new Subject<number>();

  constructor(
    private element: ElementRef<HTMLDivElement>,
    private renderer: Renderer2
  ) {
    // Inicializa as classes utilizadas para identificar as colunas
    for (let i = 1; i <= this.columnsNumber; i += 1) {
      this.columnsType.push({
        className: `col-grid-${i}`,
        columnNumber: i,
      });
    }
  }

  ngAfterViewInit(): void {
    this.recalculate();
    this.addListenerRowDataChanged();
    this.containerWidthChanged
      .pipe(debounceTime(200), distinctUntilChanged())
      .subscribe((value) => {
        this.autoResizeColumns(value);
      });

    this.container = this.element.nativeElement.querySelector(".ag-header");
    this.observer = new ResizeObserver((entries: any[]) => {
      // eslint-disable-next-line no-restricted-syntax
      for (const entry of entries) {
        const cr = entry.contentRect;
        this.containerWidthChanged.next(cr.width);
      }
    });
    this.observer.observe(this.container);
  }

  ngOnDestroy(): void {
    this.observer.unobserve(this.container);
    this.containerWidthChanged.unsubscribe();
  }

  private addListenerRowDataChanged() {
    this.gridOptions.onRowDataChanged = this.recalculate.bind(this);
  }

  /**
   * Define o tamanho das colunas automaticamente com base no conteúdo
   * @param event
   */
  private autoResizeColumns(containerWidth) {
    const isColumnApiNull = this.gridOptions.columnApi == null;

    if (isColumnApiNull) {
      return;
    }

    const allColumnIds = [];
    this.gridOptions.columnApi.getAllColumns().forEach((column) => {
      allColumnIds.push(column.getId());
    });
    this.gridOptions.columnApi.autoSizeColumns(allColumnIds);

    const widthColumns = this.gridOptions.columnApi
      .getAllColumns()
      .map((column: Column) => column.getActualWidth())
      .reduce((currentWidth, totalWidth) => currentWidth + totalWidth, 0);

    if (containerWidth > widthColumns) {
      this.gridOptions.columnApi.getAllColumns().forEach((column: Column) => {
        const fatorMultiplicacao = BigDecimal.divide(
          column.getActualWidth(),
          widthColumns
        );
        const columnWidth = BigDecimal.multiply(
          fatorMultiplicacao,
          containerWidth - this.gridGutterWidth
        );
        column.setActualWidth(columnWidth);
      });
    }

    if (this.pinnedRows !== "auto") {
      this.recalculateContainerHeight();
    }
  }

  /**
   * Recalcula a altura máxima do ag-grid. O cálculo é feito com base em quantas
   * linhas devem ser exibidas no grid então a altura do grid é fixada a partir disso.
   */
  private recalculateContainerHeight(): void {
    let numberItems = (this.rowData || []).length;
    numberItems =
      numberItems < Number(this.pinnedRows)
        ? numberItems
        : Number(this.pinnedRows);
    const headerHeight =
      this.element.nativeElement
        .querySelector(".ag-header-viewport")
        .getBoundingClientRect().height + 6;

    const containerHeight = numberItems * this.defaultRowHeight;
    if (headerHeight) {
      this.renderer.removeStyle(this.element.nativeElement, "height");
      this.renderer.setStyle(
        this.element.nativeElement,
        "height",
        `${Math.max(containerHeight + headerHeight, 59)}px`
      );
    }
  }

  private recsizeGridColumnsByAPI() {
    if (this.gridOptions && this.gridOptions.columnApi) {
      // Caputra o tamanho do container removendo o gutter para que a soma das colunas não ultrapasse
      // o tamanho total do container gerando um scroll bar indesejado.
      this.containerWidth =
        this.element.nativeElement.getBoundingClientRect().width -
        this.gridGutterWidth;

      const percentByColumn = 100 / this.columnsNumber / 100;
      this.gridOptions.columnDefs.forEach((column) => {
        const columnQuantity = Number(
          column.cellClass.replace(/(col-grid-)([0-9]+)|(.+)/g, "$2") || "1"
        );

        const columnWidth =
          percentByColumn * columnQuantity * this.containerWidth;
        this.gridOptions.columnApi.setColumnWidth(column.field, columnWidth);
      });
      if (this.pinnedRows !== "auto") {
        this.recalculateContainerHeight();
      }
    }
  }

  private recalculate() {
    const isGridEmpty = this.rowData && !this.rowData.length;

    if (isGridEmpty || !this.autoSizeColumns) {
      this.recsizeGridColumnsByAPI();
      return;
    }

    if (this.container) {
      this.autoResizeColumns(this.container.clientWidth);
    }
  }
}
