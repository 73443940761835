import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import { Observable } from "rxjs";
import { AppConfig } from "../../app.config";
import { OauthService } from "../oauth/oauth.service";

@Injectable()
export class AuthAppGuard  {
  constructor(private oauthService: OauthService) {}

  canActivate(): Observable<boolean> | Promise<boolean> | boolean {
    if (AppConfig.OAUTH_DATA) {
      return true;
    }

    const data: string = localStorage.getItem("@Report:oauth");

    if (!data) {
      this.oauthService.doLogout();

      return false;
    }

    AppConfig.OAUTH_DATA = JSON.parse(data);

    return true;
  }

  canActivateChild(
    childRoute: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean | Observable<boolean> | Promise<boolean> {
    return this.canActivate();
  }
}
