import {
  Directive,
  ElementRef,
  forwardRef,
  HostListener,
  Inject,
  KeyValueDiffer,
  KeyValueDiffers,
  Input,
  OnInit,
  Optional,
  EventEmitter,
  Output,
} from "@angular/core";
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";
import {
  CurrencyMaskConfig,
  CURRENCY_MASK_CONFIG,
  CurrencyMaskInputMode,
} from "./currency-mask.config";
import { InputHandler } from "./input.handler";
import { KeyCode } from "@app/shared/enumerations/keycode.enum";

export const CURRENCYMASKDIRECTIVE_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => CurrencyMaskDirective),
  multi: true,
};

/**
 * @whatItDoes Aplica em tempo de digitação uma máscara decimal
 * com formatação que pode ser customizada a partir parâmetros passados
 *
 * @ref https://github.com/nbfontana/ngx-currency
 * @version 2.5.2
 *
 * @class CurrencyMaskDirective
 */
@Directive({
  selector: "[currencyMask]",
  providers: [CURRENCYMASKDIRECTIVE_VALUE_ACCESSOR],
})
export class CurrencyMaskDirective implements ControlValueAccessor, OnInit {
  @Input() options: Partial<CurrencyMaskConfig> = {};

  @Output("blurEvent") blurEvent: EventEmitter<any> = new EventEmitter();

  @Output("inputEvent") inputEvent: EventEmitter<any> = new EventEmitter();

  public inputHandler: InputHandler;
  public keyValueDiffer: KeyValueDiffer<any, any>;

  public optionsTemplate: CurrencyMaskConfig = {
    align: "left",
    allowNegative: false,
    allowZero: true,
    decimal: ",",
    precision: 2,
    prefix: "",
    suffix: "",
    thousands: ".",
    nullable: false,
    inputMode: CurrencyMaskInputMode.NATURAL,
  };

  constructor(
    @Optional()
    @Inject(CURRENCY_MASK_CONFIG)
    private currencyMaskConfig: CurrencyMaskConfig,
    private elementRef: ElementRef,
    private keyValueDiffers: KeyValueDiffers
  ) {
    if (currencyMaskConfig) {
      this.optionsTemplate = currencyMaskConfig;
    }

    this.keyValueDiffer = keyValueDiffers.find({}).create();
  }

  ngAfterContentInit() {
    this.elementRef.nativeElement.style.textAlign =
      this.options && this.options.align
        ? this.options.align
        : this.optionsTemplate.align;

    if (!this.elementRef.nativeElement.value.length) {
      this.inputHandler.setValue(0);
    }
  }

  ngDoCheck() {
    if (this.keyValueDiffer.diff(this.options)) {
      this.elementRef.nativeElement.style.textAlign = this.options.align
        ? this.options.align
        : this.optionsTemplate.align;
      this.inputHandler.updateOptions(
        (<any>Object).assign({}, this.optionsTemplate, this.options)
      );
    }
  }

  ngOnInit() {
    this.inputHandler = new InputHandler(
      this.elementRef.nativeElement,
      (<any>Object).assign({}, this.optionsTemplate, this.options)
    );
  }

  @HostListener("blur", ["$event"])
  handleBlur(event: any) {
    this.inputHandler.getOnModelTouched().apply(event);
    this.blurEvent.emit();
  }

  @HostListener("cut", ["$event"])
  handleCut(event: any) {
    if (!this.isChromeAndroid()) {
      !this.isReadOnly() && this.inputHandler.handleCut(event);
    }
  }

  @HostListener("input", ["$event"])
  handleInput(event: any) {
    if (this.isChromeAndroid()) {
      !this.isReadOnly() && this.inputHandler.handleInput(event);
    }

    this.emitInputEvent(event);
  }

  @HostListener("keydown", ["$event"])
  handleKeydown(event: any) {
    if (!this.isChromeAndroid()) {
      !this.isReadOnly() && this.inputHandler.handleKeydown(event);
    }

    this.emitInputEvent(event);
  }

  @HostListener("keypress", ["$event"])
  handleKeypress(event: any) {
    if (!this.isChromeAndroid()) {
      !this.isReadOnly() && this.inputHandler.handleKeypress(event);
    }

    this.emitInputEvent(event);
  }

  @HostListener("paste", ["$event"])
  handlePaste(event: any) {
    if (!this.isChromeAndroid()) {
      !this.isReadOnly() && this.inputHandler.handlePaste(event);
    }
  }

  @HostListener("drop", ["$event"])
  handleDrop(event: any) {
    if (!this.isChromeAndroid()) {
      event.preventDefault();
    }
  }

  isChromeAndroid(): boolean {
    return (
      /chrome/i.test(navigator.userAgent) &&
      /android/i.test(navigator.userAgent)
    );
  }

  isReadOnly(): boolean {
    return this.elementRef.nativeElement.hasAttribute("readonly");
  }

  registerOnChange(callbackFunction: Function): void {
    this.inputHandler.setOnModelChange(callbackFunction);
  }

  registerOnTouched(callbackFunction: Function): void {
    this.inputHandler.setOnModelTouched(callbackFunction);
  }

  setDisabledState(value: boolean): void {
    this.elementRef.nativeElement.disabled = value;
  }

  writeValue(value: number): void {
    this.inputHandler.setValue(value);
  }

  emitInputEvent(event: KeyboardEvent) {
    if (
      event.key === KeyCode.BACKSPACE ||
      event.key === KeyCode.DELETE ||
      event.key.match(/[0-9,]/)
    ) {
      this.inputEvent.emit(event);
    }
  }
}
