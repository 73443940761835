import { InjectionToken } from "@angular/core";

/**
 * Interface com as opções de uso da diretiva
 *
 * @param align - Alinhamento do texto no input. (default: right)
 * @param allowNegative - Se true o campo aceita valores negativos. (default: true)
 * @param decimal - Caracter separador dos decimais (',')
 * @param precision - Número de casas decimais (default: 2)
 * @param prefix - Prefixo monetário ('$ ')
 * @param suffix - Sufixo monetário ('')
 * @param thousands - Separador dos milhares ('.')
 * @param nullable - Se true, ao limpar o campo, o valor ficará nulo, se não, ficará como zero
 * @param min - Valor mínimo (default: undefined)
 * @param max - Valor máximo (default: undefined)
 * @param inputMode - Determine como opera os número quando o usuário digita (default: FINANCIAL)
 */
export interface CurrencyMaskConfig {
  align: string;
  allowNegative: boolean;
  allowZero: boolean;
  decimal: string;
  precision: number;
  prefix: string;
  suffix: string;
  thousands: string;
  nullable: boolean;
  min?: number;
  max?: number;
  inputMode?: CurrencyMaskInputMode;
}

/**
 * Enum com as opções de digitação
 *
 * @param FINANCIAL - Começa a digitar a partir da maior precisão.
 * @example
 * Digitando '12' resulta em '0.12'
 * Digitando '1234' resulta em '12.34'
 * Digitando '1.234' resulta em '12.34'
 * @param NATURAL - Começa a digitar a partir da esquerda, em ordem natural. For example:
 * @example
 * Digitando '1234' resulta em '1234'
 * Digitando '1.234' resulta em '1.23'
 * Digitando '12.34' resulta em '12.34'
 * Digitando '123.4' resulta em '123.40'
 */
export enum CurrencyMaskInputMode {
  FINANCIAL,
  NATURAL,
}

export let CURRENCY_MASK_CONFIG = new InjectionToken<CurrencyMaskConfig>("currency.mask.config");
