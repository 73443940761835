import { AfterViewChecked, Directive, ElementRef, Renderer2 } from '@angular/core';

@Directive({
  selector: '[agGridResizeHeader]',
})
export class AgGridResizeHeaderDirective implements AfterViewChecked {
  /**
   * Tamanho do grid criado pelo ag-grid
   * @type {number}
   */
  private containerWidth: number;

  /**
   * Tamanho do grid fixo
   * @type {number}
   */
  private pinnedContainerWidth: number;

  /**
   * Todas as colunas do grid
   * @type {NodeList}
   */
  private columns: NodeList;

  /**
   * Tamanho máximo de uma coluna
   * @type {number}
   */
  private columnsNumber = 12;

  /**
   * Definição da coluna com nome da classe e tamanho
   * @type {any[]}
   */
  private columnsType: Array<{ className: string; columnNumber: number }> = [];

  /**
   * Construtor.
   *
   * @param element
   * @param renderer
   */
  constructor(private element: ElementRef, private renderer: Renderer2) {
    // Inicializa as classes utilizadas para identificar as colunas
    for (let i = 1; i <= this.columnsNumber; i++) {
      this.columnsType.push({
        className: `col-grid-${i}`,
        columnNumber: i,
      });
    }
  }

  ngAfterViewChecked() {
    this.containerWidth = this.element.nativeElement.getBoundingClientRect().width;

    this.recalculateSizeColumns('ag-header-group-cell');
    // Importante: Essa função deve ser executada logo após identificar
    // as colunas fixas para minimizar efeitos visuais indesejados.
    this.recalculatePinnedContainerPosition();
  }

  /**
   * Recalcula o tamanho de cada coluna do grid com base no
   * valor representado pela porcentagem de 12/100.
   *
   * @param {string} columnClass
   */
  private recalculateSizeColumns(columnClass: string): void {
    this.columns = this.element.nativeElement.querySelectorAll(`.${columnClass}`);
    this.pinnedContainerWidth = 0;

    for (let i = 0; i < this.columns.length; i++) {
      const column = this.columns.item(i);
      const { classList } = <any>column;

      this.columnsType.every(columnType => {
        const contains = classList.contains(columnType.className);
        const percentByColumn = 100 / this.columnsNumber / 100;
        const columnWidth = Math.round(
          percentByColumn * columnType.columnNumber * this.containerWidth
        );

        if (contains) {
          this.renderer.removeStyle(column, 'width');
          this.renderer.setStyle(column, 'width', `${columnWidth}px`);
        }

        // calcula o width pinned somente se for uma coluna do cabeçalho
        if (contains && classList.contains('pinned-cell')) {
          this.pinnedContainerWidth += columnWidth;
        }

        return !contains;
      });
    }
  }

  /**
   * Modifica o tamanho do grid fixo com base no tamanho das colunas fixas.
   * O Ag-grid executa seu próprio calculo para definir esse valor, porem causa inconsistência  quando
   * utilizado colunas que possuam seu tamanho  definido por porcentagem.
   *
   */
  private recalculatePinnedContainerPosition(): void {
    const pinnedLeftHeader = this.element.nativeElement.querySelector('.ag-pinned-left-header');
    const headerViewport = this.element.nativeElement.querySelector('.ag-header-viewport');
    const pinnedLeftColsViewport = this.element.nativeElement.querySelector(
      '.ag-pinned-left-cols-viewport'
    );
    const pinnedLeftColsContainer = this.element.nativeElement.querySelector(
      '.ag-pinned-left-cols-container'
    );
    const bodyViewportWrapper = this.element.nativeElement.querySelector(
      '.ag-body-viewport-wrapper'
    );

    if (this.pinnedContainerWidth) {
      this.renderer.setStyle(pinnedLeftHeader, 'width', `${this.pinnedContainerWidth}px`);
      this.renderer.setStyle(headerViewport, 'margin-left', `${this.pinnedContainerWidth}px`);
      this.renderer.setStyle(pinnedLeftColsViewport, 'width', `${this.pinnedContainerWidth}px`);
      this.renderer.setStyle(pinnedLeftColsContainer, 'width', `${this.pinnedContainerWidth}px`);
      this.renderer.setStyle(bodyViewportWrapper, 'margin-left', `${this.pinnedContainerWidth}px`);
    }
  }
}
