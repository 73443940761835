export enum KeyCode {
  ENTER = 'Enter',
  ESC = 'Escape',
  BACKSPACE = 'Backspace',
  DELETE = 'Delete',
  F1 = 'F1',
  F2 = 'F2',
  F3 = 'F3',
  F4 = 'F4',
  F5 = 'F5',
  F9 = 'F9',
}
