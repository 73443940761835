/**
 * Configura os aspectos da table do AgGrid, informando as opções mais utilizadas.
 *
 * @type any
 */
export const AgGridOptions: any = {
  rowHeight: 48,
  headerHeight: 56,
  rowSelection: 'single',
  suppressCellSelection: true,
  suppressClickEdit: true,
  suppressMultiSort: true,
  suppressColumnVirtualisation: true,
  suppressRowDrag: true,
  suppressMovableColumns: true,
  localeText: { noRowsToShow: ' ' },
};
