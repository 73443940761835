import { HttpClient } from "@angular/common/http";
import { RequestResponse } from "./../../shared/http/request-response";
import { ClienteModel } from "./../../shared/models/cliente/cliente.model";
import { Injectable } from "@angular/core";
import { AbstractUrlService } from "@app/shared/http/AbstractUrlService";

@Injectable()
export class ClienteReadService extends AbstractUrlService {
  get endPoint(): string {
    return this.apiUrlReport + "/cliente";
  }

  constructor(private http: HttpClient) {
    super();
  }

  findById(id: number): Promise<RequestResponse<ClienteModel>> {
    return this.http
      .get<RequestResponse<ClienteModel>>(this.endPoint + "/" + id)
      .toPromise();
  }
}
